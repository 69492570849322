import styled from 'styled-components';
import Link from 'next/link';
import { useState, useEffect } from 'react';
// components
import Icon from 'cds/icons';
import FillButton from 'cds/buttons/Fill';
import TextButton from 'cds/buttons/Text';
// utils
import deviceChecker from 'lib/utils/deviceChecker';
// styles
import * as typography from 'cds/typography';
import { mediaQuery, palette, zIndex, onlyHover } from 'cds/styles';

/**
 * Footer
 * @param {object} props
 * @param {boolean} props.disableFooter
 */
const Footer = ({ disableFooter }) => {
  const [isApp, setIsApp] = useState(true);

  // 고객센터 연결 - 카톡 플러스 친구 챗
  const onCustomerCenter = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_KEY);
    }

    window.Kakao.PlusFriend.chat({
      plusFriendId: process.env.NEXT_PUBLIC_KAKAO_ID,
    });
  };

  useEffect(() => {
    const device = deviceChecker();
    if (device.app()) {
      setIsApp(true);
    } else {
      setIsApp(false);
    }
  }, []);

  return (
    <Layout id="footer" isDisplay={!isApp} isMobileDisplay={!disableFooter}>
      <Inner>
        <Logo>
          <a href="/">
            <Icon name="logo_grey" width={110} height={19} fill={palette.grey050} />
          </a>
        </Logo>

        <Content>
          <Information>
            <Company>(주)클래스유</Company>
            <span>사업자번호 : 479-86-00516 | 대표자 : 김대형</span>
            <span>통신판매업신고번호 : 제2019-서울마포-0108호</span>
            <span>개인정보보호책임자 : Jacob, Kim(class_customer@enfit.net) 02-335-3320</span>
            <span>서울특별시 중구 을지로 264, 1103호(을지로6가, 롯데FiTin)</span>
          </Information>

          <Information>
            <CsCenter>
              고객센터<span>(월-금 오전 08:30 ~ 오후 05:00)</span>
            </CsCenter>
            <CsButton
              type="button"
              color={palette.grey030}
              size={'Regular'}
              onClick={onCustomerCenter}
            >
              카카오톡 문의하기
            </CsButton>
          </Information>

          <Social>
            <li>
              <Link href="https://www.instagram.com/classu._.official/">
                <a target="_blank" rel="noopener noreferrer">
                  <Icon
                    name="ic_instagram"
                    width={22}
                    height={22}
                    fill={palette.grey50}
                    stroke={palette.grey50}
                  />
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://www.facebook.com/classtok/">
                <a target="_blank" rel="noopener noreferrer">
                  <Icon name="ic_facebook" width={12} height={22} fill={palette.grey50} />
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://www.youtube.com/channel/UCQ9WEzhuxE4UR69Ku4kQVSA">
                <a target="_blank" rel="noopener noreferrer">
                  <Icon name="ic_youtube" width={26} height={18} fill={palette.grey50} />
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://blog.naver.com/classu_official_">
                <a target="_blank" rel="noopener noreferrer">
                  <Icon name="ic_blog" width={24} height={21} fill={palette.grey50} />
                </a>
              </Link>
            </li>
          </Social>

          <Policy>
            <li />
            <li>
              <Link href={`https://classu.co.kr/new/biz`} passHref>
                <TextButton as="a" rel="noopener noreferrer" size="Regular" target="_blank">
                  기업/단체 교육 문의
                </TextButton>
              </Link>
            </li>
            <li>
              <Link href={`/notice`} passHref>
                <TextButton as="a" rel="noopener noreferrer" size="Regular">
                  공지사항
                </TextButton>
              </Link>
            </li>
            <li>
              <Link href={`/customer/faq`} passHref>
                <TextButton as="a" rel="noopener noreferrer" size="Regular">
                  FAQ
                </TextButton>
              </Link>
            </li>
            <li>
              <Link href={`/customer/agreement`} passHref>
                <TextButton as="a" rel="noopener noreferrer" size="Regular">
                  이용약관
                </TextButton>
              </Link>
            </li>
            <li>
              <Link href={`/customer/policy`} passHref>
                <TextButton as="a" rel="noopener noreferrer" size="Regular">
                  개인정보처리방침
                </TextButton>
              </Link>
            </li>
          </Policy>
          <Copyright>© CLASSU Inc.</Copyright>
        </Content>
      </Inner>
    </Layout>
  );
};

const Layout = styled.footer`
  position: relative;
  display: ${({ isDisplay, isMobileDisplay }) => (isDisplay && isMobileDisplay ? 'block' : 'none')};
  width: 100%;
  padding: 48px 16px 64px;
  background-color: ${palette.system.BLACK};
  z-index: ${zIndex.footer};

  ${mediaQuery.large} {
    display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
    padding: 48px 0 64px;
  }
`;

const Inner = styled.div`
  width: 1800px;
  max-width: 100%;

  ${mediaQuery.large} {
    display: flex;
    padding: 0 32px;
    margin: 0 auto;
  }

  ${mediaQuery.xlarge} {
    display: flex;
    padding: 0 72px;
    margin: 0 auto;
  }
`;

const Logo = styled.div`
  a {
    flex: 0 auto;
    display: inline-block;
    margin-bottom: 32px;
    font-size: 0;

    ${mediaQuery.large} {
      margin-bottom: 0;
    }

    & svg {
      ${mediaQuery.large} {
        width: 140px;
        height: 23px;
      }
    }
  }

  ${mediaQuery.large} {
    flex: 0 auto;
    width: 28%;
  }
`;

const Content = styled.div`
  ${mediaQuery.medium} {
    & > * {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2);
    }
  }

  ${mediaQuery.large} {
    flex: 0 auto;
    width: 72%;
  }
`;

const Information = styled.div`
  margin-bottom: 32px;

  ${mediaQuery.large} {
    margin-bottom: 24px;

    & ~ & {
      padding-left: 16px;
    }
  }

  & > span {
    display: block;
    ${typography.caption2_medium}
    font-weight: 400;
    color: ${palette.grey25};

    ${mediaQuery.large} {
      font-weight: 400;
    }
  }

  & a {
    color: ${palette.grey50};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.p`
  ${typography.caption1_medium}
  color: ${palette.grey50};
`;

const Company = styled(Title)`
  margin-bottom: 12px;
  color: ${palette.grey50};
  ${mediaQuery.medium} {
    margin-bottom: 16px;
    ${typography.subtitle4_medium}
  }
`;

const CsCenter = styled(Title)`
  margin-bottom: 16px;

  span {
    margin-left: 4px;
    ${typography.caption3_regular}
    color: ${palette.grey25};
  }
`;

const CsButton = styled(FillButton)`
  width: 100%;
  max-width: 100%;
  ${typography.body2_regular}
  color: ${palette.grey50};
  background-color: ${palette.BTN.BG};

  ${mediaQuery.medium} {
    width: 248px;
  }
`;

const Social = styled.ul`
  margin-bottom: 32px;

  ${mediaQuery.medium} {
    margin-bottom: 24px;
  }

  & li {
    overflow: hidden;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: ${palette.BTN.BG};
    font-size: 0;
    vertical-align: top;
  }

  & li ~ li {
    margin-left: 16px;
  }

  & a {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const Policy = styled.ul`
  margin-bottom: 24px;

  ${mediaQuery.large} {
    padding-left: 16px;
    margin-bottom: 0;
  }

  & li {
    width: auto;
    font-size: 0;

    ${mediaQuery.medium} {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      line-height: 40px;
    }
  }

  & li:nth-child(even) {
    ${mediaQuery.medium} {
      padding-left: 12px;
    }

    ${mediaQuery.xlarge} {
      padding-left: 32px;
    }
  }

  & ${TextButton} {
    background-color: rgba(0, 0, 0, 0);
    ${typography.caption3_medium}
    color: ${palette.grey25};
    justify-content: flex-start;
    cursor: pointer;
  }
`;

const Copyright = styled.p`
  ${typography.caption3_regular}
  color: ${palette.grey25};

  ${mediaQuery.medium} {
    white-space: nowrap;
  }
`;

export default Footer;
