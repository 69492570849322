import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
// components
import AutoComplete from 'components/molecules/modal/search/AutoComplete';
import IconButton from 'cds/buttons/Icon';
import Icon from 'cds/icons';
// slices
import { postAutoCompleteKeyword } from 'slices/search.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
import useThrottle from 'hooks/common/event/useThrottle';
// styles
import { palette } from 'cds/styles';

/**
 * 헤더 검색 Input
 * @param {object} props
 * @param {'Light' | 'Dark'} props.mode 모드
 */
const SearchInput = ({ mode = 'Light', ...props }) => {
  const router = useRouter();
  const { keyword } = router.query;

  const dispatch = useDispatch();
  const autoCompletedClassList = useSelector(state => state.search.autoCompletedKeywordList);

  const [isShowAutoComplete, setIsShowAutoComplete] = useState(false);
  const [isShowErase, setIsShowErase] = useState(false);
  const [word, setWord] = useState('');
  const searchRef = useRef(null);
  const isMounted = useRef(false);
  const isSearched = useRef(false);

  const throttle = useThrottle();

  const onSearch = () => {
    if (!searchRef.current) {
      return;
    }
    let value = searchRef.current.value;
    value = value.replace(/#/g, '');
    router.push(`/search?keyword=${value}`);
  };

  const onAutoComplete = value => {
    setWord(value);

    dispatch(postAutoCompleteKeyword({ keyword: value })).finally(() => {
      if (!isMounted.current) {
        return;
      }

      if (value.trim().length > 0 && !isSearched.current) {
        setIsShowAutoComplete(true);
        setIsShowErase(true);
      } else {
        setIsShowAutoComplete(false);
        setIsShowErase(false);
      }
    });
  };

  const onCloseAutoComplete = () => {
    setIsShowAutoComplete(false);
    setIsShowErase(false);
  };

  const onClear = () => {
    setIsShowAutoComplete(false);
    setIsShowErase(false);
    searchRef.current.value = '';
  };

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      e.target.blur();
      onSearch();
      setIsShowAutoComplete(false);
      isSearched.current = true;
    } else {
      isSearched.current = false;
    }
  };

  const onChange = throttle.run(e => {
    const { value } = e.target;
    onAutoComplete(value);
  }, 400);

  const onFocus = e =>
    window.setTimeout(() => {
      const { value } = e.target;

      isSearched.current = false;
      onAutoComplete(value);
    }, 100);

  /** 초기화 작업 */
  useEffect(() => {
    isSearched.current = false;
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  /** 검색입력칸 값 변경 */
  useEffect(() => {
    if (searchRef.current && keyword) {
      searchRef.current.value = keyword;
    }
  }, [keyword]);

  /** 검색 후, 타 페이지 접속시 키워드 삭제하기 */
  useEffect(() => {
    if (keyword) {
      return;
    }

    if (searchRef.current.value) {
      searchRef.current.value = '';
    }
  }, [keyword, searchRef]);

  return (
    <Layout mode={mode}>
      {isShowAutoComplete && (
        <AutoComplete
          keyword={word}
          keywordList={autoCompletedClassList}
          onClose={onCloseAutoComplete}
        />
      )}

      <Input
        type="text"
        placeholder="무엇을 배우고 싶나요?"
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onChange={onChange}
        defaultValue={keyword}
        ref={searchRef}
        mode={mode}
        {...props}
      />

      <SearchButton mode={mode} onClick={onSearch}>
        <Icon name="ic_search" width={24} height={24} fill={palette.grey50} />
      </SearchButton>

      {isShowErase && (
        <EraseButton width={32} height={22} onClick={onClear}>
          <Icon name="ic_close_circle_m" width={24} height={24} fill={palette.BTN.BG} />
        </EraseButton>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 640px;
  height: 44px;
  padding: 10px 20px;
  border: ${({ mode }) =>
    mode === 'Light' ? `1px solid ${palette.system.grey}` : `1px solid ${palette.system.grey}`};
  border-radius: 22px;
  background-color: transparent;
`;

const Input = styled.input`
  flex: 1;
  padding: 0 32px 0 0;
  color: ${({ mode }) => (mode === 'Light' ? palette.grey75 : palette.white)};
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::placeholder {
    color: ${({ mode }) => (mode === 'Light' ? palette.grey0 : palette.font.tertiary2)};
  }
`;

const SearchButton = styled(IconButton)`
  margin-left: 8px;
`;

const EraseButton = styled(IconButton)`
  position: absolute;
  right: 48px;
  top: 10px;

  &:active {
    & svg {
      transition: fill 0.1s ease;
      fill: ${palette.grey060};
    }
  }
`;

export default SearchInput;
